import React from 'react'
import { Layout, Card, Table, Spin, Input, Form, message, DatePicker, Modal, Col, Row, Popconfirm, Button, Collapse, Select } from 'antd'
import { PlusCircleFilled, EditOutlined, CloseOutlined, DeleteOutlined, } from "@ant-design/icons"
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import AutoCompleteSelect from '../../common/AutoCompleteSelect/AutoCompleteSelect'
import { useState } from 'react'
import ApiClient from '../../helpers/ApiClient'
import moment from "moment";
import { useEffect } from 'react'
import "../../admin/Adminstyles.scss";
import { exportExcel } from "../ExportExcel";

const { Content } = Layout
const { Panel } = Collapse;
const { Option } = Select;

const UserIPAddressConfig = () => {

    let initialReqObj = {
        filter: {
            userId: 0,
            ipAddress: null
        },
        page: 0

    }
    let initialUserIPLogObj = {
        filter: {
            searchType: "2", //default Today record
            fromDate: null,
            toDate: null,
            ipAddress: null,
            userId: 0
        },
        page: 0

    }
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm()
    const [IpLogForm] = Form.useForm()
    const [userIpAddressList, setUserIpAdressList] = useState([])
    const [UserIPConfigTableList, setUserIPConfigTableList] = useState([]);
    const [currentId, setCurrentId] = useState(-1);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [userIdOnUpdate, setUserIdOnUpdate] = useState(-1)
    const [isDateDisplay, setIsDateDisplay] = useState(false);
    const [arrivalDate, setArrivalDate] = useState(null);
    const [weekendDate, setWeekendDate] = useState(null);
    const [userIPLogsData, setUserIPLogsData] = useState([])
    const [showIPLogTable, setShowIPLogTable] = useState(false);
    const [loadingIPLogs, setLoadingIPLogs] = useState(true);
    const [clients, setClients] = useState([]);
    const [ipFalse, setIpFalse] = useState(false);


    useEffect(() => {

        getUserIpAddressList(initialReqObj)
        // getUserIPAddressLogs(initialUserIPLogObj)
    }, [])

    const getUserIpAddressList = (reqBody) => {
        ApiClient.post("user/useripaddress/list", reqBody)
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.results.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });
                    setUserIpAdressList(data);
                    setUserIPConfigTableList(data);
                    setClients(data);
                } else message.error(res.message, 3);
            })
            .catch((error) => {
                message.error(error.message ?? "Failed To get", 3);
                console.error(error);
            });

    }

    const getUserIPAddressLogs = (IPLogReq) => {
        ApiClient.post("user/useripaddress/logs", IPLogReq)
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.results.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });
                    setUserIPLogsData(data);
                    setShowIPLogTable(true);
                    setLoadingIPLogs(false)
                } else { setUserIPLogsData([]); message.error(res.message, 3); }
            })
            .catch((error) => {
                console.log("hhhhhh")
                setUserIPLogsData([]);
                message.error(error.message ?? "Failed To get", 3);
                console.error(error);
            });
    }

    const onSubmitForm = (FormData) => {




        const ReqObj = {
            userId: FormData.userId,
            ipAddress: FormData.ipAddress,
        }
        if (showUpdateBtn) UpdateUserIpAddress(ReqObj);
        else SaveUserIpAddress(ReqObj);
    }

    const handelOnSubmitIPLogForm = (val) => {
        let LogObj = {
            filter: {
                searchType: val.searchType ?? "2", //default Today record
                fromDate: val.fromDate ?? null,
                toDate: val.toDate ?? null,
                ipAddress: val.ipAddress ?? null,
                userId: val.userId ?? 0
            },
            page: 0
        }
        getUserIPAddressLogs(LogObj)
    }

    const SaveUserIpAddress = (ReqObj) => {
        ApiClient.post("user/saveuseripaddress", ReqObj)
            .then((res) => {
                if (res.isSuccess) {
                    message.success(res.message ?? "IpAddress Added Successfully", 3);
                    setModalVisible(false);
                    getUserIpAddressList(initialReqObj)
                } else {
                    setModalVisible(false);
                    message.error(res.message ?? "IpAddress Already existed", 3);
                }
            })
            .catch((error) => {
                setModalVisible(false);
                message.error("Failed To Add", 3);
                console.error(error);
            });
    }

    const UpdateUserIpAddress = (val) => {
        val.userId = userIdOnUpdate
        ApiClient.put(`user/updateuseripaddress/${currentId}`, {}, val)
            .then(
                (resp) => {
                    if (resp.isSuccess) {
                        getUserIpAddressList(initialReqObj);
                        setModalVisible(false);
                        message.success(resp.message ?? "Updated successfully", 3);
                        setCurrentId(-1);
                    } else message.error(resp.message ?? "Failed to udate IpAddress", 3)
                }
            )
            .catch((error) => {
                setModalVisible(false);
                message.error("Failed To Update", 3);
                console.error(error);
            });;
    }

    const DeleteUserIpAddress = (Id) => {

        ApiClient.delete("user/deleteuseripaddress/" + Id)
            .then((res) => {
                if (res.isSuccess) {
                    message.success(res.message ?? "IpAddress Deleted  successfully", 3);
                    getUserIpAddressList(initialReqObj);
                } else message.error(res.message ?? "Failed To Deleted IpAddress", 3)
            })
            .catch((error) => {
                console.error(error);
                message.error("Failed To Deleted IpAddress", 3)
            });
    }


    const editCurrentItem = (id, data) => {
        setShowUpdateBtn(true);
        setCurrentId(id);
        setUserIdOnUpdate(data.userId)
        form.setFieldsValue({
            ...data,
            userId: data.userName
        });
        setModalVisible(true);
    };


    const closeSearch = () => {
        setShowSearchBox(false);
        setUserIPConfigTableList(userIpAddressList);
    };

    let lastIndex = 0;
    const uniqueKey = () => {
        return lastIndex++;
    };

    const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

    const tableActions = (Id, data) => {

        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => editCurrentItem(Id, data)}
                        style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure want to delete?"
                        onConfirm={() => DeleteUserIpAddress(Id)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const searchData = () => {
        setShowSearchBox(true);
    };

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = UserIPConfigTableList.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setUserIPConfigTableList(searchResults);
        } else {
            setUserIPConfigTableList(userIpAddressList);
        }
    };

    const handleModal = () => {
        if (showUpdateBtn) {
            setShowUpdateBtn(false);
            form.resetFields();
        }
        setModalVisible(true);
    };

    const handleExcel = (ExcelList, isfromIPLog) => {
        let excelData = ExcelList;
        excelData = excelData.map((item, i) => ({
            SNo: i + 1,
            UserName: item.userName,
            IpAddress: item.ipAddress,
            CreatedDate: moment(item.createdOn).format("DD-MM-YYYY HH:mm"),
        }));
        let excelName = isfromIPLog ? "UserIpAddressLog Reports" : "UserIPAddressConfig Reports"
        exportExcel(excelData, excelName);
    };

    const onChangeFromDate = (current, dateString) => {
        // Can not select days before today and toda
        setArrivalDate(dateString);
    };
    const onChangeToDate = (current, dateString) => {
        // Can not select days before today and today
        setWeekendDate(dateString);
    };
    const disabledFromDate = (current) => {
        if (weekendDate != null) {
            return current.valueOf() > (new Date(weekendDate));
        }
        // return current && current > moment().endOf('day');

        return current.valueOf() > Date.now();
    }
    const disabledToDate = (current) => {
        // Can not select days before today and today
        return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
    };

    const handleTimeAndDate = (value) => {

        if (value === "5") {
            setIsDateDisplay(true);
        } else {
            setIsDateDisplay(false);
        }
    };

    const columns = [
        // {
        //     title: "Sr. No.",
        //     dataIndex: "SNo",
        //     sorter: (a, b) => a.SNo - b.SNo,
        // },
        {
            title: "UserName",
            dataIndex: "userName",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "IpAddress",
            dataIndex: "ipAddress",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "CreatedDate",
            dataIndex: "createdOn",
            render: (createdOn) => moment(createdOn).format("DD-MM-YYYY HH:mm")
        },
        {
            title: "Actions",
            dataIndex: "id",
            render: (id, data) => tableActions(id, data),
        },
    ]

    const CheckclientId = (id) => {
        console.log(id, "check", clients);
        if (!id || !clients) return;

        // Filter the clients object to find all records with the matching userId
        const matchedClients = clients.filter(client => client.userId === id);

        // Check if there are more than 3 entries for the client
        if (matchedClients.length >= 3) {
            setIpFalse(true);
            message.error("You cannot add more than 3 entries for this client.");
            return;
        }
        setIpFalse(false);

    }
    const validateIpAddress = (_, value) => {


        // Split the IP by dots
        const ipRegex = /^(25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})(\.(25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})){3}$/;
        return ipRegex.test(value) ? Promise.resolve() : Promise.reject(new Error("Invalid IP Address"));

    };
    const handleModalClose = () => {
        setModalVisible(false);
        setIpFalse(false); // Reset ipFalse when the modal is closed
    };

    return (
        <Layout className="head-layout-check">
            <div className="manage-markup-section direction-collapse">
                <Collapse accordion defaultActiveKey={['1']} className="collapse-head-css">
                    <Panel header="User IPAddress Configuration" key="1">
                        <Content className="container-fluid">
                            <div className="card-bt-gap">
                                <Card bordered={false} style={{ borderRadius: "25px" }}>
                                    <div className="card-add-heading">
                                        <div className="rows-count">
                                            <h5>View UserIpAddress</h5>
                                            <p>{UserIPConfigTableList.length} Rows Found !</p>
                                        </div>
                                        <div className="action-images">
                                            {showSearchBox && (
                                                <Input
                                                    autocomplete="newpassword"
                                                    placeholder="Search"
                                                    onChange={(e) => searchTableData(e)}
                                                    suffix={closeSearchInput}
                                                    style={{ padding: "0px 12px" }}
                                                />
                                            )}
                                            &nbsp;&nbsp;
                                            <img src={search} alt="search" onClick={() => searchData()} />
                                            <img src={excel} alt="excel" onClick={() => handleExcel(UserIPConfigTableList, false)} />
                                            <img
                                                src={exchange}
                                                alt="exchange"
                                                onClick={() => {
                                                    setUserIPConfigTableList([]);
                                                    getUserIpAddressList(initialReqObj);
                                                }}
                                            />
                                            <p className="add-deposit-icon" onClick={handleModal}>
                                                <PlusCircleFilled />
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <Table
                                            scroll={{ x: true }}
                                            bordered
                                            rowKey={uniqueKey}
                                            dataSource={UserIPConfigTableList}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 25,
                                                showSizeChanger: true,
                                                pageSizeOptions: ["25", "50", "100", "125"],
                                            }}
                                        />
                                    </div>
                                </Card>
                            </div>
                        </Content>
                    </Panel>
                    <Panel header="User IPAddress Logs" key="2">
                        <Content className="container-fluid">
                            <div className="card-bt-gap">
                                <Card bordered={false} style={{ borderRadius: "25px" }}>
                                    <Row className="card-add-heading">
                                        <Col span={24}>
                                            <div className="card-bt-gap">
                                                <h5>User IPAddress Logs</h5>

                                                <Form
                                                    form={IpLogForm}
                                                    layout={"vertical"}
                                                    onFinish={handelOnSubmitIPLogForm}
                                                >
                                                    <Row gutter={12} align="middle">

                                                        <Col md={5} sm={12} xs={24}>
                                                            <AutoCompleteSelect
                                                                formItemProps={{
                                                                    label: " ",
                                                                    name: "userId",
                                                                    // rules: [
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: "Please enter User Name",
                                                                    //     },
                                                                    // ],
                                                                }}
                                                                selectProps={{
                                                                    placeholder: "Please Enter User Name",
                                                                }}
                                                                api={"user/userautosuggestion/"}
                                                                keytext="name"
                                                                keyvalue="id"
                                                            />
                                                        </Col>

                                                        <Col md={5} xs={24}>
                                                            <Form.Item
                                                                label=" "
                                                                name="searchType"

                                                            >
                                                                <Select placeholder="select Status" onChange={handleTimeAndDate} defaultValue="2">
                                                                    <Option value="1">Lasthour </Option>
                                                                    <Option value="2">Today </Option>
                                                                    <Option value="3">Yesterday </Option>
                                                                    <Option value="4">Lastweek </Option>
                                                                    <Option value="5">CustomDates  </Option>
                                                                </Select>
                                                            </Form.Item>

                                                        </Col>
                                                        {isDateDisplay ? (<>
                                                            <Col md={4} sm={12} xs={24}>
                                                                <Form.Item
                                                                    label=" "
                                                                    name="fromDate"

                                                                >
                                                                    <DatePicker
                                                                        format="YYYY-MM-DD"
                                                                        disabledDate={disabledFromDate}
                                                                        onChange={(date, dateString) =>
                                                                            onChangeFromDate(date, dateString)

                                                                        }
                                                                        placeholder="Please Enter From Date"
                                                                        style={{ width: "100%" }}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col md={4} sm={12} xs={24}>
                                                                <Form.Item
                                                                    label=" "
                                                                    name="toDate"
                                                                >
                                                                    <DatePicker
                                                                        format="YYYY-MM-DD"
                                                                        disabledDate={disabledToDate}
                                                                        onChange={(date, dateString) =>
                                                                            onChangeToDate(date, dateString)

                                                                        }
                                                                        placeholder="Please Enter To Date"
                                                                        style={{ width: "100%" }}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </>) : null}
                                                        <Col md={4} sm={12} xs={24}>
                                                            <Form.Item
                                                                label=" "
                                                                name="ipAddress"
                                                            >
                                                                <Input placeholder="Enter IPAddress" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col >
                                                            <Button
                                                                key="add"
                                                                className="site-admin-btn-11"
                                                                type="primary"
                                                                onClick={IpLogForm.submit}
                                                            >
                                                                Search
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </Col>
                                        {
                                            showIPLogTable ? (<>

                                                <Col span={24}>
                                                    {userIPLogsData.length > 0 ? (<>
                                                        <div className="card-add-heading">
                                                            <div className="rows-count">
                                                                <p>{userIPLogsData.length} Rows Found !</p>
                                                            </div>
                                                            <div className="action-images">
                                                                <img src={excel} alt="excel" onClick={() => handleExcel(userIPLogsData, true)} />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Table
                                                                scroll={{ x: true }}
                                                                bordered
                                                                rowKey={uniqueKey}
                                                                dataSource={userIPLogsData}
                                                                columns={columns}
                                                                pagination={{
                                                                    defaultPageSize: 25,
                                                                    showSizeChanger: true,
                                                                    pageSizeOptions: ["25", "50", "100", "125"],
                                                                }}
                                                            />
                                                        </div>
                                                    </>)
                                                        : loadingIPLogs ? (<>
                                                            <Spin
                                                                size="large"
                                                                tip="Loading..."
                                                                style={{ width: "100%" }}
                                                            />
                                                        </>)
                                                            : (
                                                                <b>No Results Found!</b>
                                                            )}
                                                </Col>

                                            </>) : null
                                        }
                                    </Row>
                                </Card>
                            </div>
                        </Content>
                    </Panel>
                </Collapse>
            </div>
            <Modal
                title={[
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>
                            <strong>
                                {showUpdateBtn ? "Update UserIPAddress" : "Add UserIPAddress"}
                            </strong>
                        </h6>
                    </div>,
                ]}
                className="promo-modal-header"
                visible={modalVisible}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                footer={[
                    <Button key="add" type="primary" onClick={form.submit}>
                        {showUpdateBtn ? "Update" : "Add"}
                    </Button>,
                ]}
            >
                <Form layout='vertical' form={form} onFinish={onSubmitForm}>
                    <Row>
                        <Col className="gutter-row" md={12} xs={24}>
                            <AutoCompleteSelect
                                formItemProps={{
                                    label: "User",
                                    name: "userId",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please enter User Name",
                                        },
                                    ],
                                }}
                                selectProps={{
                                    placeholder: "Please Enter UserName",
                                }}
                                api={"user/userautosuggestion/"}
                                keytext="name"
                                keyvalue="id"
                                onChange={(value) => CheckclientId(value)}
                            />
                        </Col>
                        <Col className="gutter-row" md={12} xs={24}>
                            <Form.Item name="ipAddress" label="IPAddress" rules={[{ required: true, message: "Please Provide IP" }, { validator: validateIpAddress }]}>
                                <Input autoComplete='newpassword' placeholder='Enter IPAddress' disabled={ipFalse} />

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Layout>
    )
}

export default UserIPAddressConfig;