import {
    Button,
    Card,
    Col,
    Collapse,
    Switch,
    DatePicker,
    Form,
    Layout,
    Row,
    Select,
    Spin,
    Tabs,
    Table,
    Input,
    message
} from "antd";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, BarChart, Bar, PieChart, Pie } from 'recharts';
import moment from "moment";
import React, { useState, useEffect } from "react";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import "./TopRoutes.scss"
import { exportExcel } from "../ExportExcel";
import excel from "../assets/vector-icons/excel.png";

import APIClient from "../../helpers/BusApiClient";

import { Link, useHistory } from "react-router-dom";
const { Option } = Select;
const { Content } = Layout;

export const getTicketDetails = (tktNo, record) => {

    let url = "/admin/bus_ticket";
    return <Link to={url + "?refNo=" + tktNo}>{tktNo}</Link>;
};

const TopBusRoutes = () => {
    let history = useHistory();
    const [reportRole, setReportRole] = useState(0);
    const [form] = Form.useForm();
    const [tableList, setTableList] = useState([]);
    const [reportsList, setReportsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const { user } = useAuthContext();
    const [columns, setColumns] = useState([])
    const [isDateDisplay, setIsDateDisplay] = useState(false);

    const presentDate = moment();
    // const [arrivalDate, setArrivalDate] = useState(moment());
    // const [weekendDate, setWeekendDate] = useState(moment())
    const [arrivalDate, setArrivalDate] = useState(null);
    const [weekendDate, setWeekendDate] = useState(null);
    const { TabPane } = Tabs;

    const TopBusRoutesColumns = [
        {
            title: "Sr.No",
            dataIndex: "SNo",
        },
        {
            title: "Source State",
            dataIndex: "sourceState",
        },
        {
            title: "Destination State",
            dataIndex: "destinationState",
        },
        // {
        //     title: "Journey Date",
        //     dataIndex: "journeyDate",
        //     sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
        //     render: (journeyDate) => moment(journeyDate).format("DD-MM-YYYY HH:MM"),
        // },
        {
            title: "Routes",
            dataIndex: "route",
        },
        {
            title: "Count",
            dataIndex: "count",
        }
    ];

    useEffect(() => {
        form.setFieldsValue({
            searchType: "2"
        })
        let TopRoutes = {
            filter: {
                status: 0,
                searchType: 2,
                fromDate: null,
                toDate: null,
                api: null,
                userId: (user.id == 1) ? null : user.id,
                isRtc: false

            },
            page: 0
        }
        getTopBusRoutes(TopRoutes);
    }, [])

    const onFormSubmit = (val) => {

        let TopRoutes = {
            filter: {
                status: val.status ?? 0,
                searchType: val.searchType ?? 4,
                fromDate: val.fromDate ?? null,
                toDate: val.toDate ?? null,
                api: (val.api == 'NONE' || val.api == undefined) ? null : val.api,
                //userId: user.role.toUpperCase() === "ADMIN" ? (val.userId ?? null) : user.id,
                userId: (user.role.toUpperCase() === "ADMIN" || user.role === "BusinessAnalyst") ? (val.userId ?? null) : user.id,
                isRtc: false
            },
            page: 0
        };
        getTopBusRoutes(TopRoutes);
    };
    const getTopBusRoutes = (obj) => {
        setLoading(true);
        setShowTable(true);
        setReportsList([]);
        setTableList([]);
        ApiClient.post(`dashboard/topbusroutes`, obj)
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.results.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });
                    setReportsList(data);
                    setTableList(data);
                    // setTable(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleTimeAndDate = (value) => {

        if (value === "5") {
            setIsDateDisplay(true);
        } else {
            setIsDateDisplay(false);
        }
    };

    const apisOptions = [
        { name: "NONE", value: "NONE" },
        { name: "IRCT", value: "IRCT" },
        { name: "REDB", value: "REDB" },
        { name: "BTLA", value: "BTLA" },
        { name: "EZIF", value: "EZIF" },
        { name: "TRYR", value: "TRYR" },
        { name: "ETST", value: "ETST" },
        { name: "ITSG", value: "ITSG" },
        { name: "VRLT", value: "VRLT" },
        { name: "ZGBS", value: "ZGBS" },
        { name: "YLBS", value: "YLBS" },
        { name: "ABHI", value: "ABHI" },

    ]

    const handleExcel = () => {
        let excelData = tableList;
        excelData = excelData.map((item, i) => ({
            SNo: i + 1,
            Source: item.source,
            Destination: item.destination,
            Routes: item.route,
            Count: item.count,

        }));
        exportExcel(excelData, "TopBus Routes");
    };


    // const disaleFromDate = (currentDate) => {
    //     return currentDate < moment(presentDate).startOf("day");
    // };

    // const disaleToDate = (currentDate) => {
    //     //return currentDate < moment(arrivalDate).startOf("day");

    //     return (currentDate < moment(arrivalDate).startOf("day") || (currentDate > moment(weekendDate).startOf("day")))
    // };

    // const onChangeFromDate = (momentdate, _) => {

    //     setWeekendDate(moment(momentdate, "DD-MM-YYYY").add(7, 'days'));
    //     setArrivalDate(momentdate);
    // }
    //custome dates disable past and future dates
    const onChangeFromDate = (current, dateString) => {
        // Can not select days before today and toda
        setArrivalDate(dateString);
    };
    const onChangeToDate = (current, dateString) => {
        // Can not select days before today and today
        setWeekendDate(dateString);
    };
    const disabledFromDate = (current) => {
        if (weekendDate != null) {
            return current.valueOf() > (new Date(weekendDate));
        }
        // return current && current > moment().endOf('day');

        return current.valueOf() > Date.now();
    }
    const disabledToDate = (current) => {
        // Can not select days before today and today
        return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
    };


    return (
        <div className="outer-background-all">
            <div className="manage-markup-section">
                <Content className="container-fluid">
                    <div className="card-bt-gap">
                        <Card bordered={false} style={{ borderRadius: "25px" }}>
                            <Row className="card-add-heading">
                                <Col span={24}>
                                    <div className="card-bt-gap">
                                        <h4> TopBus Routes</h4>
                                        <Form
                                            form={form}
                                            layout={"vertical"}
                                            onFinish={onFormSubmit}
                                        >
                                            <Row gutter={12} align="middle">
                                                {/* {user.role.toUpperCase() === "ADMIN" &&
                                                    (<Col md={5} xs={24}>
                                                        <Form.Item
                                                            label="Reports of"
                                                            name="reportsOf"
                                                        // rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select
                                                                placeholder="Select"
                                                                onSelect={(val) => setReportRole(val)}
                                                            >
                                                                <Option value={0}>Self</Option>
                                                                <Option value={5}>Specific Company</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>)} */}

                                                {/* {reportRole === 5 && (
                                                    <Col md={5} sm={12} xs={24}>
                                                        <AutoCompleteSelect
                                                            formItemProps={{
                                                                label: "Company Name",
                                                                name: "userId",
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Please enter User Name",
                                                                    },
                                                                ],
                                                            }}
                                                            selectProps={{
                                                                placeholder: "Please Enter User Name",
                                                            }}
                                                            api={"user/userautosuggestion/"}
                                                            keytext="name"
                                                            keyvalue="id"
                                                        />
                                                    </Col>
                                                )} */}

                                                {user.role.toUpperCase() === "ADMIN"
                                                    && (
                                                        <Col md={5} sm={12} xs={24}>
                                                            <AutoCompleteSelect
                                                                formItemProps={{
                                                                    label: "Company Name",
                                                                    name: "userId",
                                                                    // rules: [
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: "Please enter User Name",
                                                                    //     },
                                                                    // ],
                                                                }}
                                                                selectProps={{
                                                                    placeholder: "User Name",
                                                                }}
                                                                api={"user/userautosuggestion/"}
                                                                keytext="name"
                                                                keyvalue="id"
                                                            />
                                                        </Col>
                                                    )}

                                                <Col md={4} sm={12} xs={24}>
                                                    <Form.Item
                                                        label="Search Type"
                                                        name="searchType"
                                                    // rules={[
                                                    //   { required: true, message: "Please enter To Date" },
                                                    // ]}
                                                    >
                                                        <Select placeholder="Please select Status" onChange={handleTimeAndDate} defaultValue="2">
                                                            <Option value="1">Lasthour </Option>
                                                            <Option value="2">Today </Option>
                                                            <Option value="3">Yesterday </Option>
                                                            <Option value="4">Lastweek </Option>
                                                            <Option value="5">CustomDates  </Option>

                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                {isDateDisplay ? (<>
                                                    <Col md={4} sm={12} xs={24}>
                                                        <Form.Item
                                                            label="From Date"
                                                            name="fromDate"
                                                        >
                                                            <DatePicker
                                                                format="YYYY-MM-DD"
                                                                placeholder="From Date"

                                                                //  disabledDate={disaleFromDate}
                                                                // onChange={(date, dateString) =>
                                                                //     onChangeFromDate(date, dateString)
                                                                // }
                                                                onChange={(date, dateString) =>
                                                                    onChangeFromDate(date, dateString)
                                                                }
                                                                disabledDate={disabledFromDate}
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={4} sm={12} xs={24}>
                                                        <Form.Item
                                                            label="To Date"
                                                            name="toDate"

                                                        >
                                                            <DatePicker
                                                                format="YYYY-MM-DD"
                                                                // disabledDate={disaleToDate}
                                                                disabledDate={disabledToDate}
                                                                onChange={(date, dateString) =>
                                                                    onChangeToDate(date, dateString)

                                                                }
                                                                placeholder="To Date"
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </>) : null}
                                                {user.role.toUpperCase() === "ADMIN"
                                                    && (
                                                        <Col md={4} sm={12} xs={24}>
                                                            <Form.Item name="api" label="Supplier">
                                                                <Select placeholder="Service Name">
                                                                    {apisOptions.map(item => (<Option value={item.value}>{item.name}</Option>))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    )}
                                                <Col md={4} sm={12} xs={24}>
                                                    <Form.Item
                                                        label="Status"
                                                        name="status"
                                                    // rules={[
                                                    //   { required: true, message: "Please enter To Date" },
                                                    // ]}
                                                    >
                                                        <Select placeholder="Status"  >
                                                            <Option value={0}>NONE </Option>
                                                            <Option value={1}>BLOCKED </Option>
                                                            <Option value={2}>CONFIRMED </Option>
                                                            <Option value={3}>TRAVELLED </Option>
                                                            <Option value={4}>CANCELLED </Option>
                                                            <Option value={5}>CANCELLED PARTIALLY </Option>
                                                            <Option value={6}>BLOCK FAILED </Option>
                                                            <Option value={7}>BLOCK PENDING </Option>
                                                            <Option value={8}>BOOK FAILED </Option>
                                                            <Option value={9}>BOOK PENDING </Option>
                                                            <Option value={10}>CANCEL FAILED </Option>
                                                            <Option value={11}>CANCEL PENDING </Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                {/* <Col md={4} sm={12} xs={24}>
                                                    <Form.Item
                                                        label="Result Type"
                                                        name="showType"
                                                    >
                                                        <Select
                                                            placeholder="Select"
                                                            onSelect={(val) => setReportRole(val)}
                                                        >
                                                            <Option value="Consolidate">Consolidate</Option>
                                                            <Option value="Distinct">Distinct </Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col> */}
                                                <Col md={4} sm={12} xs={24}  >
                                                    <Button
                                                        key="add"
                                                        className="site-admin-btn-11"
                                                        type="primary"
                                                        onClick={form.submit}
                                                        style={{ marginBottom: "4%" }}
                                                    >
                                                        Search
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                                {showTable ? (
                                    <Col span={24}>
                                        {tableList.length > 0 ? (
                                            <>
                                                <div className="card-add-heading">
                                                    <div className="rows-count">
                                                        <div>
                                                            <h5>{tableList.length} TopBus Routes</h5>
                                                        </div>
                                                    </div>
                                                    <div className="action-images">
                                                        {/* {showSearchBox && (
                                    <Input
                                      placeholder="Search"
                                      onChange={(e) => searchTableData(e)}
                                      suffix={closeSearchInput}
                                      style={{ padding: "0px 12px" }}
                                    />
                                  )}
                                  &nbsp;&nbsp;
                                  <img
                                    src={search}
                                    alt="search"
                                    onClick={(e) => searchData()}
                                  /> */}
                                                        <img
                                                            src={excel}
                                                            alt="excel"
                                                            style={{ marginBottom: "10px", marginRight: "60px", zIndex: "99" }}
                                                            onClick={() => handleExcel()}
                                                        />
                                                    </div>



                                                </div>




                                                <Tabs defaultActiveKey="1" className='main-tab-buttons1'>
                                                    <TabPane
                                                        tab={
                                                            <i class="fa fa-table" style={{ fontSize: "24px" }}></i>
                                                        }
                                                        key="1"
                                                    >
                                                        <div className="mt-2">
                                                            <Table
                                                                scroll={{ x: true }}
                                                                bordered
                                                                dataSource={tableList}
                                                                // columns={busColumns}
                                                                columns={TopBusRoutesColumns}
                                                                pagination={{
                                                                    defaultPageSize: 10,
                                                                    showSizeChanger: true,
                                                                    pageSizeOptions: ["10", "25", "50", "100", "125"],
                                                                }}
                                                            />
                                                        </div>                                                        </TabPane>
                                                    <TabPane
                                                        tab={
                                                            <i class="fa fa-line-chart" style={{ fontSize: "24px" }}></i>
                                                        }
                                                        key="2"
                                                    >
                                                        <ResponsiveContainer width="100%" height="100%" aspect={2}  >
                                                            <LineChart data={tableList} width={500} height={300} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="route" />
                                                                <YAxis />
                                                                <Tooltip contentStyle={{ backgroundColor: '#e8e8e9' }} />
                                                                <Legend />
                                                                <Line type="monotone" dataKey="count" stroke="red" activeDot={{ r: 8 }} />

                                                            </LineChart>
                                                        </ResponsiveContainer>

                                                    </TabPane>
                                                </Tabs>
                                            </>
                                        ) : loading ? (
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{ width: "100%" }}
                                            />
                                        ) : (
                                            <b>No Results Found!</b>
                                        )}
                                    </Col>
                                ) : (
                                    ""
                                )}
                            </Row>
                        </Card>
                    </div>
                </Content>
            </div>
        </div>
    );
};

export default TopBusRoutes;
